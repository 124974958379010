import no1 from './1.png'
import no2 from './2.png'
import no3 from './3.png'
import no4 from './4.png'
import no5 from './5.png'
import no6 from './6.png'
import no7 from './7.png'
import no8 from './8.png'
import no9 from './9.png'
import no10 from './10.png'
import no11 from './11.png'
import no12 from './12.png'
import no13 from './13.png'
import no14 from './14.png'
import no15 from './15.png'
import no16 from './16.png'
import no17 from './17.png'
import no18 from './18.png'
import no19 from './19.png'
import no20 from './20.png'
import no21 from './21.png'
import no22 from './22.png'
import no23 from './23.png'
import no24 from './24.png'
import no25 from './25.png'
import no26 from './26.png'
import no27 from './27.png'
import no28 from './28.png'
import no29 from './29.png'
import no30 from './30.png'
import no31 from './31.png'
import no32 from './32.png'
import no33 from './33.png'
import no34 from './34.png'
import no35 from './35.png'
import no36 from './36.png'
import no37 from './37.png'
import no38 from './38.png'
import no39 from './39.png'
import no40 from './40.png'
import no41 from './41.png'
import no42 from './42.png'
import no43 from './43.png'
import no44 from './44.png'
import no45 from './45.png'
import no46 from './46.png'
import no47 from './47.png'
import no48 from './48.png'
import no49 from './49.png'
import no50 from './50.png'
import no51 from './51.png'
import no52 from './52.png'
import no53 from './53.png'
import no54 from './54.png'
import no55 from './55.png'
import no56 from './56.png'
import no57 from './57.png'
import no58 from './58.png'
import no59 from './59.png'
import no60 from './60.png'
import no61 from './61.png'
import no62 from './62.png'
import no63 from './63.png'
import no64 from './64.png'
import no65 from './65.png'
import no66 from './66.png'

// ㄘ
import no67 from './67.png'

// 0124
import no68 from './i0.png'
import no69 from './i1.png'
import no70 from './i2.png'
import no71 from './i4.png'

const bopomofo = {
  1: { image: no1, name: 'ㄅ', value: '1'},
  2: { image: no2, name: 'ㄆ', value: '2'},
  3: { image: no3, name: 'ㄇ', value: '3'},
  4: { image: no4, name: 'ㄈ', value: '4' },
  5: { image: no5, name: 'ㄅ"', value: '5' },
  6: { image: no6, name: 'ㄆ"', value: '6' },
  7: { image: no7, name: 'ㄇ"', value: '7' },
  8: { image: no8, name: 'ㄈf', value: '8' },
  9: { image: no9, name: 'ㄈv', value: '9' },
  10: { image: no10, name: '摩擦ㄉ', value: '10' },
  11: { image: no11, name: '摩擦ㄊ', value: '11' },
  12: { image: no12, name: 'ㄋ', value: '12' },
  13: { image: no13, name: 'ㄌ', value: '13' },
  14: { image: no14, name: 'ㄉ^ㄗ', value: '14' },
  15: { image: no15, name: 'ㄊ^ㄘ', value: '15' },
  16: { image: no16, name: 'ㄉ"', value: '16' },
  17: { image: no17, name: 'ㄊ"', value: '17' },
  18: { image: no18, name: 'ㄋ~', value: '18' },
  19: { image: no19, name: 'ㄌ~', value: '19' },
  20: { image: no20, name: 'ㄌ-', value: '20' },
  21: { image: no21, name: 'ㄍ', value: '21' },
  22: { image: no22, name: 'ㄎ', value: '22' },
  23: { image: no23, name: 'ㄏ', value: '23' },
  24: { image: no24, name: 'ㄍ^"', value: '24' },
  25: { image: no25, name: 'ㄎ^"', value: '25' },
  26: { image: no26, name: 'ㄐ', value: '26' },
  27: { image: no27, name: 'ㄑ', value: '27' },
  28: { image: no28, name: 'ㄒ', value: '28' },
  29: { image: no29, name: '*ㄐ*', value: '29' },
  30: { image: no30, name: '*ㄑ*', value: '30' },
  31: { image: no31, name: '*ㄒ*', value: '31' },
  32: { image: no32, name: 'ㄓ', value: '32' },
  33: { image: no33, name: 'ㄔ', value: '33' },
  34: { image: no34, name: 'ㄕ', value: '34' },
  35: { image: no35, name: 'ㄖ', value: '35' },
  36: { image: no36, name: 'ㄗ', value: '36' },
  37: { image: no37, name: 'ㄘ', value: '37' },
  38: { image: no38, name: 'ㄙ', value: '38' },
  39: { image: no39, name: 'Z', value: '39' },
  40: { image: no40, name: 'ㄙ^Θ', value: '40' },
  41: { image: no41, name: 'Z^Θ', value: '41' },
  42: { image: no42, name: 'ㄚ', value: '42' },
  43: { image: no43, name: 'ㄛ', value: '43' },
  44: { image: no44, name: 'ㄜ', value: '44' },
  45: { image: no45, name: 'ㄝ', value: '45' },
  46: { image: no46, name: '心ㄚ', value: '46' },
  47: { image: no47, name: 'ㄦ', value: '47' },
  48: { image: no48, name: 'ㄧ^ㄝ', value: '48' },
  49: { image: no49, name: 'ㄚ^ㄝ', value: '49' },
  50: { image: no50, name: 'ㄠ^ㄝ', value: '50' },
  51: { image: no51, name: 'ㄞ', value: '51' },
  52: { image: no52, name: 'ㄟ', value: '52' },
  53: { image: no53, name: 'ㄠ', value: '53' },
  54: { image: no54, name: 'ㄡ', value: '54' },
  55: { image: no55, name: 'ㄢ', value: '55' },
  56: { image: no56, name: 'ㄣ', value: '56' },
  57: { image: no57, name: 'ㄤ', value: '57' },
  58: { image: no58, name: 'ㄥ', value: '58' },
  59: { image: no59, name: 'ㄧ', value: '59' },
  60: { image: no60, name: 'ㄨ', value: '60' },
  61: { image: no61, name: 'ㄩ', value: '61' },
  62: { image: no62, name: 'ㄜ^ㄧ', value: '62' },
  63: { image: no63, name: 'ㄜ^ㄨ', value: '63' },
  64: { image: no64, name: 'ㄨ^ㄜ', value: '64' },
  65: { image: no65, name: 'ㄨ^iㄓ', value: '65' },
  66: { image: no66, name: 'ㄧㄧ', value: '66' },
  67: { image: no67, name: 'ㄘ^Θ', value: '67' },
  68: { image: no68, name: '輕聲', value: '68' },
  69: { image: no69, name: 'ㄧ聲', value: '69' },
  70: { image: no70, name: '二聲', value: '70' },
  71: { image: no71, name: '四聲', value: '71' },
}

export default bopomofo;
