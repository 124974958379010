const commonSettings = {
  title: '蕭博士SoR美語正音師「聽力筆試測驗Ｊ」',
  description: [
    '（測驗說明） 本測驗共三部分：',
    '【第一部分】請參照《臺灣雙語注音符號表》並在對應題號中，寫出聽到聲音的編號（每題二分，共十題，佔二十分）',
    '【第二部分】請參照《臺灣雙語注音符號表》並在對應題號中，寫出音檔所念CVC組合聲音的編號 （每題三分，計分方式為子音一分；母音兩分扣完為止，共二十五題，佔七十五分）',
    '【第三部分】請聽學員音檔，先找出對方值得鼓勵的部分，並寫出一點，該學員發音所產生明顯的偏移誤差，再試答，若您是正音師會如何引導與回覆。（共一題，佔五分）',
    '＊注意一：本測驗回答前兩大題時，只需填寫聽到該聲音符號的編號(非填編號不計分)',
    '＊注意二：回答第二部分題目時，請按照CVC發音先後順序填寫，並在編號之間請加上（.）做區隔 ex: 13.53.22。',
    '＊總分確認後將寄發成績至本表單記錄之信箱，請務必填寫正確。',
    '＊總分需達90分以上，方達到正音師筆試及格標準。',
  ],
  endDescription: '總分確認後將寄發成績至本表單記錄之信箱，請務必填寫正確。',
  commonQuestion: [
    {
      id: 'entry.172276031',
      questionTitle: '師資班別',
      questionType: 'radio',
      questionOptions: [
        "SoR師資ㄧ班",
        "SoR師資二班",
        "SoR師資三班",
        "SoR師資四班",
        "SoR師資五班",
        "SoR師資六班",
        "SoR師資七班",
        "SoR師資八班",
        "SoR師資九班",
        "SoR師資十班",
      ],
    },
    {
      id: 'entry.573948846',
      questionTitle: '師資姓名',
      questionType: 'text',
    },
    {
      id: 'entry.2033835759',
      questionTitle: '考試序號',
      questionDescription: '如上線名稱為01-安迪哥，請輸入01',
      questionType: 'text',
      inputType: 'number',
    },
  ],
  googleFormResponseURL: "https://docs.google.com/forms/u/0/d/e/1FAIpQLSfpT12Ly2Be0AKOnnuAlsDwMVItwbYZaeXkT5C_XxSCccaR8A/formResponse"
}


const part1Settings = {
  title: '【第一部分】 聽出《臺灣雙語符號表》個別符號  （每題兩分，共十題）',
  onlyCustomKeyboard: true,
  bopomofoKeyboard: true,
}

const part1Questions = [
  {
    id: 'entry.1062532100',
    questionTitle: '第一題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.315045706',
    questionTitle: '第二題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.424153291',
    questionTitle: '第三題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.886790481',
    questionTitle: '第四題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.168045119',
    questionTitle: '第五題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.757586012',
    questionTitle: '第六題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.2021541880',
    questionTitle: '第七題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.1791408647',
    questionTitle: '第八題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.1922610091',
    questionTitle: '第九題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.1452080461',
    questionTitle: '第十題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
]

const part2Settings = {
  title: '【第二部分】 聽出CVC組合的聲音（ 每題三分，共二十五題）',
  description: '請聽音檔，在對應題號中，依序寫出CVC組合的聲音在《臺灣雙語注音符號表》的編號',
  onlyCustomKeyboard: true,
  bopomofoKeyboard: true,
}

const part2Questions = [
  {
    id: 'entry.129660470',
    questionTitle: '第一題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.443571259',
    questionTitle: '第二題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.950072603',
    questionTitle: '第三題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.1691461409',
    questionTitle: '第四題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.1749489632',
    questionTitle: '第五題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.2120291620',
    questionTitle: '第六題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.88536496',
    questionTitle: '第七題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.743766707',
    questionTitle: '第八題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.325451342',
    questionTitle: '第九題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.602021393',
    questionTitle: '第十題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.480602353',
    questionTitle: '第十一題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.2082125563',
    questionTitle: '第十二題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.204249988',
    questionTitle: '第十三題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.714185888',
    questionTitle: '第十四題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.505537057',
    questionTitle: '第十五題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.1857846538',
    questionTitle: '第十六題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.195684583',
    questionTitle: '第十七題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.1248606910',
    questionTitle: '第十八題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.621575898',
    questionTitle: '第十九題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.1219137104',
    questionTitle: '第二十題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.1817705071',
    questionTitle: '第二十一題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.1801778234',
    questionTitle: '第二十二題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.2144500851',
    questionTitle: '第二十三題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.1230447615',
    questionTitle: '第二十四題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
  {
    id: 'entry.358450166',
    questionTitle: '第二十五題',
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'text',
  },
]

const part3Settings = {
  title: '【第三部分】偵錯給回饋 （每題五分，共一題）',
  description: [
    '1.請聽學員音檔，先找出對方值得鼓勵的部分，並盡可能寫下該學員發音所產生的偏移問題',
    '2.再試答若您是正音師，會如何引導與回覆',
  ],
  bopomofoKeyboard: true,
}

const part3Questions = [
  {
    id: 'entry.164876408',
    questionTitle: '偵錯題（ㄙㄓㄨㄝ stre）',
    questionDescription: '聽該學員音檔，答題時請先簡短精準寫出一項您觀察到對方的優點；偏移部分請寫出主要希望對方調整的一項，並盡可能條列詳答如何一步步協助對方調音的過程。',
    questionHint: [
      '讚美鼓勵點：',
      '主要發音問題：',
      '調整方法：',
      'a.',
      'b.',
      'c.',
      'd.',
    ],
    questionType: 'audio',
    questionAudio: 'https://example.com/audio/1.mp3',
    inputType: 'textarea',
  },
]

const exportData = {
  settings: commonSettings,
  questions: [
    {
      settings: part1Settings,
      questions: part1Questions,
    },
    {
      settings: part2Settings,
      questions: part2Questions,
    },
    {
      settings: part3Settings,
      questions: part3Questions,
    },
  ]
}

export default exportData;