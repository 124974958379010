import imageData from './assets/bopomofo/index';

let currentInputElement = null;
let isKeyboardVisible = false;

export function createSorKeyboard(container) {
  const keyboardWrapper = document.createElement('div');
  keyboardWrapper.id = 'sor-keyboard-wrapper';
  keyboardWrapper.style.display = 'none';

  const toggleButton = document.createElement('button');
  toggleButton.textContent = '顯示/隱藏鍵盤';
  toggleButton.id = 'toggle-keyboard-button';
  toggleButton.addEventListener('click', toggleKeyboard);

  const keyboardContainer = document.createElement('div');
  keyboardContainer.id = 'sor-keyboard';
  keyboardContainer.className = 'keyboard-container';

  // 創建鍵盤佈局
  const keyboardLayout = [
    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
    [23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33],
    [34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44],
    [45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55],
    [56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67],
    [68, 69, 70, 71],
    ['clear', 'backspace']
  ];

  // 創建鍵盤
  keyboardLayout.forEach(row => {
    const rowElement = document.createElement('div');
    rowElement.className = 'keyboard-row';
    row.forEach(key => {
      const keyElement = document.createElement('button');
      keyElement.className = 'keyboard-key';
      keyElement.type = 'button';

      if (key === 'clear') {
        keyElement.textContent = '清除';
        keyElement.classList.add('function-key', 'clear-key');
        keyElement.addEventListener('click', clearInput);
      } else if (key === 'backspace') {
        keyElement.textContent = '刪除';
        keyElement.classList.add('function-key', 'backspace-key');
        keyElement.addEventListener('click', backspace);
      } else {
        keyElement.dataset.value = imageData[key].value;
        keyElement.dataset.image = imageData[key].image;

        const imgElement = document.createElement('img');
        imgElement.src = imageData[key].image;
        imgElement.alt = imageData[key].name;
        imgElement.dataset.name = imageData[key].name;
        imgElement.className = 'keyboard-key-image';

        keyElement.appendChild(imgElement);
        keyElement.addEventListener('click', () => addInputImage(keyElement.dataset.image, keyElement.dataset.value, imageData[key].name));
      }
      rowElement.appendChild(keyElement);
    });
    keyboardContainer.appendChild(rowElement);
  });

  keyboardWrapper.appendChild(keyboardContainer);
  container.appendChild(toggleButton);
  container.appendChild(keyboardWrapper);
}

function toggleKeyboard() {
  const keyboardWrapper = document.getElementById('sor-keyboard-wrapper');
  const toggleButton = document.getElementById('toggle-keyboard-button');

  if (!currentInputElement || !currentInputElement.isContentEditable) {
    alert('請先選擇一個輸入框');
    return;
  }

  isKeyboardVisible = !isKeyboardVisible;
  keyboardWrapper.style.display = isKeyboardVisible ? 'block' : 'none';
  toggleButton.textContent = isKeyboardVisible ? '隱藏鍵盤' : '顯示鍵盤';
}

export function setCurrentInputElement(inputElement) {
  currentInputElement = inputElement;
}

function addInputImage(imageSrc, value, name) {
  if (!currentInputElement) {
    console.warn('No input element focused. Cannot add image.');
    return;
  }

  // Check if the current input element is contentEditable
  if (!currentInputElement.isContentEditable) {
    console.warn('Current input element is not contentEditable. Cannot add image.');
    return;
  }

  const imgElement = document.createElement('img');
  imgElement.src = imageSrc;
  imgElement.alt = value;
  imgElement.dataset.name = name;
  imgElement.className = 'input-image';
  imgElement.dataset.value = value;
  
  const selection = window.getSelection();
  const range = selection.getRangeAt(0);

  // Check if the range is within the current input element
  if (!currentInputElement.contains(range.commonAncestorContainer)) {
    console.warn('Selection is outside the current input element. Focusing and moving cursor to the end.');
    currentInputElement.focus();
    range.selectNodeContents(currentInputElement);
    range.collapse(false); // Move cursor to the end
  }

  range.deleteContents();
  range.insertNode(imgElement);
  range.setStartAfter(imgElement);
  range.setEndAfter(imgElement);
  selection.removeAllRanges();
  selection.addRange(range);
  
  currentInputElement.focus();
  
  // Add this line to update the hidden input
  updateHiddenInput(currentInputElement);
}

function clearInput() {
  if (!currentInputElement) return;
  currentInputElement.innerHTML = '';
  currentInputElement.focus();
}

function backspace() {
  if (!currentInputElement) return;
  const selection = window.getSelection();
  const range = selection.getRangeAt(0);
  
  if (range.collapsed) {
    const currentNode = range.startContainer;
    const offset = range.startOffset;
    
    if (currentNode === currentInputElement && offset === 0) return;
    
    if (currentNode.nodeType === Node.TEXT_NODE) {
      if (offset > 0) {
        currentNode.textContent = currentNode.textContent.slice(0, offset - 1) + currentNode.textContent.slice(offset);
        range.setStart(currentNode, offset - 1);
        range.setEnd(currentNode, offset - 1);
      } else {
        const previousSibling = currentNode.previousSibling;
        if (previousSibling) {
          if (previousSibling.nodeType === Node.TEXT_NODE) {
            const newOffset = previousSibling.textContent.length;
            previousSibling.textContent += currentNode.textContent;
            currentNode.remove();
            range.setStart(previousSibling, newOffset);
            range.setEnd(previousSibling, newOffset);
          } else {
            previousSibling.remove();
          }
        }
      }
    } else {
      const previousSibling = currentNode.childNodes[offset - 1];
      if (previousSibling) {
        previousSibling.remove();
        range.setStart(currentNode, offset - 1);
        range.setEnd(currentNode, offset - 1);
      }
    }
  } else {
    range.deleteContents();
  }
  
  selection.removeAllRanges();
  selection.addRange(range);
  currentInputElement.focus();
  
  // Add this line to update the hidden input
  updateHiddenInput(currentInputElement);
}

// Add this new function to update the hidden input
function updateHiddenInput(inputElement) {
  const hiddenInputId = `hidden-${inputElement.id.split('-').pop()}`;
  const hiddenInput = document.getElementById(hiddenInputId);
  if (hiddenInput) {
    hiddenInput.value = convertEditableDivContent(inputElement);
    console.log(`Updated hidden input ${hiddenInputId} with value: ${hiddenInput.value}`);
  } else {
    console.warn(`Hidden input not found for ${inputElement.id}`);
  }
}

// Update this function to connect multiple images with a period
function convertEditableDivContent(editableDiv) {
  let result = [];
  let hasImage = false;

  editableDiv.childNodes.forEach(node => {
    if (node.nodeType === Node.TEXT_NODE) {
      result.push(node.textContent.trim());
    } else if (node.nodeName === 'IMG') {
      hasImage = true;
      result.push(node.dataset.value || node.alt);
    } else if (node.nodeName === 'BR') {
      result.push('\n');
    }
  });

  // If there are multiple images, join them with periods
  if (hasImage) {
    return result.filter(item => item !== '').join('.');
  } else {
    return result.join('').trim();
  }
}

export function extractSorInputValues(inputElement) {
  let values = [];

  function extractFromNode(node) {
    if (node.nodeType === Node.ELEMENT_NODE) {
      if (node.tagName === 'IMG') {
        // 如果是圖片元素，提取 data-value 屬性
        const value = node.dataset.value;
        if (value) {
          values.push(value);
        }
      } else if (node.tagName === 'BR') {
        // 如果是換行元素，添加換行符
        values.push('\n');
      } else {
        // 遞歸處理其他元素的子節點
        Array.from(node.childNodes).forEach(extractFromNode);
      }
    } else if (node.nodeType === Node.TEXT_NODE) {
      // 如果是文本節點，直接添加文本內容
      values.push(node.textContent);
    }
  }

  // 從根節點開始遞歸處理
  extractFromNode(inputElement);

  // 將所有值連接成一個字符串並返回
  return values.join('');
}
