import { createSorKeyboard, setCurrentInputElement } from './sor-keyboard';
import jQuestions from './assets/questions/j';

function createTestHeader(settings) {
  const headerDiv = document.createElement('div');
  headerDiv.className = 'test-header';
  
  const title = document.createElement('h1');
  title.textContent = settings.title;
  headerDiv.appendChild(title);
  
  const description = document.createElement('div');
  description.className = 'test-description';
  description.innerHTML = settings.description.join('<br><br>');
  headerDiv.appendChild(description);
  
  return headerDiv;
}

function createEndDescription(settings) {
  const endDescription = document.createElement('div');
  endDescription.className = 'end-description';
  endDescription.textContent = settings.endDescription;
  return endDescription;
}

function createTestContent(data) {
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = jQuestions.settings.googleFormResponseURL;
  form.className = 'test-content';
  
  // Create common questions section
  const commonQuestionsSection = createCommonQuestionsSection(data.settings.commonQuestion);
  form.appendChild(commonQuestionsSection);
  
  // Create main questions sections
  data.questions.forEach(section => {
    const sectionDiv = createSection(section);
    form.appendChild(sectionDiv);
  });
  
  return form;
}

function createCommonQuestionsSection(commonQuestions) {
  const sectionDiv = document.createElement('div');
  sectionDiv.className = 'common-questions-section';

  const sectionTitle = document.createElement('h2');
  sectionTitle.textContent = '基本資料';
  sectionDiv.appendChild(sectionTitle);

  commonQuestions.forEach(question => {
    const questionDiv = createCommonQuestion(question);
    sectionDiv.appendChild(questionDiv);
  });

  return sectionDiv;
}

function createCommonQuestion(question) {
  const questionDiv = document.createElement('div');
  questionDiv.className = 'common-question';

  const title = document.createElement('h3');
  title.textContent = question.questionTitle;
  questionDiv.appendChild(title);

  if (question.questionDescription) {
    const description = document.createElement('p');
    description.textContent = question.questionDescription;
    questionDiv.appendChild(description);
  }

  if (question.questionType === 'radio' && Array.isArray(question.questionOptions)) {
    const optionsContainer = document.createElement('div');
    optionsContainer.className = 'radio-options';
    question.questionOptions.forEach((option, index) => {
      const label = document.createElement('label');
      const radio = document.createElement('input');
      radio.type = 'radio';
      radio.name = `${question.id}`; // 確保名稱唯一
      radio.value = option;
      radio.id = `question-${question.id}-option-${index}`;
      label.htmlFor = radio.id; // 增加可訪問性
      label.appendChild(radio);
      label.appendChild(document.createTextNode(option));
      optionsContainer.appendChild(label);
    });
    questionDiv.appendChild(optionsContainer);
  } else if (question.questionType === 'text') {
    // Add answer title
    const answerTitle = document.createElement('h4');
    answerTitle.textContent = '回答';
    questionDiv.appendChild(answerTitle);

    const input = document.createElement('input');
    input.type = 'text';
    input.id = `question-${question.id}`;
    input.name = question.id; // Add name attribute
    questionDiv.appendChild(input);
  } else {
    console.warn(`Unsupported question type: ${question.questionType}`);
  }

  return questionDiv;
}

function createSection(section) {
  const sectionDiv = document.createElement('div');
  sectionDiv.className = 'test-section';

  if (section.settings) {
    const sectionTitle = document.createElement('h2');
    sectionTitle.textContent = section.settings.title;
    sectionDiv.appendChild(sectionTitle);
    
    if (section.settings.description) {
      const sectionDescription = document.createElement('div');
      sectionDescription.className = 'section-description';
      sectionDescription.innerHTML = Array.isArray(section.settings.description) 
        ? section.settings.description.join('<br><br>')
        : section.settings.description;
      sectionDiv.appendChild(sectionDescription);
    }
    
    // Store the global onlyCustomKeyboard setting
    const globalOnlyCustomKeyboard = section.settings.onlyCustomKeyboard || false;
    // Store the global bopomofoKeyboard setting
    const globalBopomofoKeyboard = section.settings.bopomofoKeyboard || false;

    if (section.questions) {
      section.questions.forEach(question => {
        // Apply global setting to each question if not explicitly set
        if (globalOnlyCustomKeyboard && question.onlyCustomKeyboard === undefined) {
          question.onlyCustomKeyboard = true;
        }
        if (globalBopomofoKeyboard && question.bopomofoKeyboard === undefined) {
          question.bopomofoKeyboard = true;
        }
        const questionDiv = createQuestion(question);
        sectionDiv.appendChild(questionDiv);
      });
    }
  }

  return sectionDiv;
}

function createQuestion(question) {
  const questionDiv = document.createElement('div');
  questionDiv.className = 'question';
  
  const title = document.createElement('h3');
  title.textContent = question.questionTitle;
  questionDiv.appendChild(title);
  
  const description = document.createElement('p');
  description.textContent = question.questionDescription;
  questionDiv.appendChild(description);
  
  if (question.questionHint) {
    const hintContainer = document.createElement('div');
    const hintTitle = document.createElement('div');
    hintTitle.className = 'question-hint-title';
    hintTitle.textContent = '提示';
    hintContainer.appendChild(hintTitle);

    hintContainer.className = 'question-hint';
    question.questionHint.forEach(hint => {
      const hintParagraph = document.createElement('p');
      hintParagraph.textContent = hint;
      hintContainer.appendChild(hintParagraph);
    });
    questionDiv.appendChild(hintContainer);
  }

  if (question.questionType === 'audio') {
    const audioTitle = document.createElement('h4');
    audioTitle.textContent = question.audioTitle || '題目音檔'; // 使用問題中的 audioTitle，如果沒有則默認為 '題目音檔'
    questionDiv.appendChild(audioTitle);

    const audio = document.createElement('audio');
    audio.controls = true;
    audio.src = question.questionAudio;
    questionDiv.appendChild(audio);
  }
  
  if (question.bopomofoKeyboard) {
    // Add answer title
    const answerTitle = document.createElement('h4');
    answerTitle.textContent = '回答';
    questionDiv.appendChild(answerTitle);

    const inputElement = document.createElement('div');
    inputElement.id = `sor-input-${question.id}`;
    inputElement.contentEditable = true;
    inputElement.className = question.inputType === 'textarea' ? 'editable-div' : 'editable-input';
    inputElement.setAttribute('data-placeholder', '請輸入文字或專利注音...');
    
    // Apply onlyCustomKeyboard setting
    if (question.onlyCustomKeyboard) {
      inputElement.setAttribute('data-placeholder', '請透過專利注音回答...');
      inputElement.addEventListener('keydown', (e) => {
        if (e.key !== 'Tab') {
          e.preventDefault();
        }
      });
    }
    
    // Add focus event to set current input element
    inputElement.addEventListener('focus', () => setCurrentInputElement(inputElement));

    questionDiv.appendChild(inputElement);

    const hiddenInput = document.createElement('input');
    hiddenInput.type = 'hidden';
    hiddenInput.name = question.id;
    hiddenInput.id = `hidden-${question.id}`;
    questionDiv.appendChild(hiddenInput);
  } else {
    // Add answer title
    const answerTitle = document.createElement('h4');
    answerTitle.textContent = '回答';
    questionDiv.appendChild(answerTitle);

    // 如果沒有設置 bopomofoKeyboard，使用常規輸入
    const input = question.inputType === 'textarea' 
      ? document.createElement('textarea') 
      : document.createElement('input');
    input.type = question.inputType === 'text' ? 'text' : question.inputType;
    input.id = `question-${question.id}`;
    input.name = question.id; // Add name attribute
    questionDiv.appendChild(input);
  }
  
  return questionDiv;
}

function createSubmitButton() {
  const submitButton = document.createElement('button');
  submitButton.textContent = '提交答案';
  submitButton.className = 'submit-button';
  submitButton.type = 'submit'; // 明確設置為 submit 類型
  return submitButton;
}

function createTestButton() {
  const testButton = document.createElement('button');
  testButton.textContent = 'Test: Prefill Answers';
  testButton.className = 'test-button';
  testButton.type = 'button'; // 設置按鈕類型為 'button'
  testButton.addEventListener('click', prefillAnswers);
  return testButton;
}

function prefillAnswers() {
  // Prefill common questions
  const commonQuestions = document.querySelectorAll('.common-question');
  commonQuestions.forEach((questionDiv, index) => {
    const input = questionDiv.querySelector('input[type="text"]');
    const radios = questionDiv.querySelectorAll('input[type="radio"]');
    
    if (input) {
      input.value = `${index + 1}`;
    } else if (radios.length > 0) {
      radios[0].checked = true; // Select the first radio option
    }
  });

  // Prefill main questions
  const mainQuestions = document.querySelectorAll('.question');
  mainQuestions.forEach((questionDiv, index) => {
    const input = questionDiv.querySelector('input[type="text"], textarea');
    const editableDiv = questionDiv.querySelector('[contenteditable]');
    
    if (input) {
      input.value = `${index + 1}`;
    } else if (editableDiv) {
      editableDiv.textContent = `${index + 1}`;
    }
  });

  console.log('All answers have been prefilled for testing.');
}

document.addEventListener('DOMContentLoaded', () => {
  const container = document.querySelector('.container');
  
  // Create and append test header
  const testHeader = createTestHeader(jQuestions.settings);
  container.appendChild(testHeader);
  
  // Create and append test content (now a form)
  const testForm = createTestContent(jQuestions);
  container.appendChild(testForm);

  // Create and append end description
  const endDescription = createEndDescription(jQuestions.settings);
  container.appendChild(endDescription);

  // Add submit button to the form
  const submitButton = createSubmitButton();
  testForm.appendChild(submitButton);

  // Add test button for prefilling answers
  const testButton = createTestButton();
  container.appendChild(testButton);

  // Create and append the fixed SOR keyboard
  const keyboardContainer = document.createElement('div');
  keyboardContainer.id = 'fixed-sor-keyboard';
  createSorKeyboard(keyboardContainer);
  container.appendChild(keyboardContainer);

  // 創建固定位置的容器來放置鍵盤和切換按鈕
  const fixedKeyboardContainer = document.createElement('div');
  fixedKeyboardContainer.id = 'fixed-keyboard-container';
  createSorKeyboard(fixedKeyboardContainer);
  document.body.appendChild(fixedKeyboardContainer);

  // Add form submit event listener
  testForm.addEventListener('submit', function(event) {
    event.preventDefault();
    if (validateForm(this)) {
      console.log('Form is valid, submitting...');
      this.submit();
    } else {
      console.log('Form validation failed');
    }
  });
});

function validateForm(form) {
  const unansweredQuestions = [];

  // Validate all inputs
  form.querySelectorAll('input[type="text"], input[type="radio"], textarea, [contenteditable]').forEach(element => {
    let value;
    if (element.type === 'radio') {
      const name = element.getAttribute('name');
      value = form.querySelector(`input[name="${name}"]:checked`) ? 'checked' : '';
    } else if (element.hasAttribute('contenteditable')) {
      value = convertEditableDivContent(element);
    } else {
      value = element.value.trim();
    }

    if (!value) {
      const questionTitle = element.closest('.question, .common-question').querySelector('h3').textContent;
      unansweredQuestions.push(questionTitle);
    }
  });

  if (unansweredQuestions.length > 0) {
    alert(`請回答以下問題：\n${unansweredQuestions.join('\n')}`);
    return false;
  }

  // Update hidden inputs for contenteditable elements
  form.querySelectorAll('[contenteditable]').forEach(element => {
    const hiddenInput = document.getElementById(`hidden-${element.id.split('-').pop()}`);
    if (hiddenInput) {
      hiddenInput.value = convertEditableDivContent(element);
      console.log(`Updated hidden input ${hiddenInput.id} with value: ${hiddenInput.value}`);
    } else {
      console.warn(`Hidden input not found for ${element.id}`);
    }
  });

  return true;
}

function convertEditableDivContent(editableDiv) {
  let result = [];
  let hasImage = false;

  editableDiv.childNodes.forEach(node => {
    if (node.nodeType === Node.TEXT_NODE) {
      result.push(node.textContent.trim());
    } else if (node.nodeName === 'IMG') {
      hasImage = true;
      result.push(node.dataset.value || node.alt);
    } else if (node.nodeName === 'BR') {
      result.push('\n');
    }
  });

  // If there are multiple images, join them with periods
  if (hasImage) {
    return result.filter(item => item !== '').join('.');
  } else {
    return result.join('').trim();
  }
}